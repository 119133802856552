import React from "react";
import "../styles/Header.css";
import {ReactComponent as Logo} from "../../src/Yardoli_Logo.svg"
import {NavLink} from "react-router-dom"

function Header(props) {
  return (
    <div>
      <header className="mainHeaderContainer">
        <section className="menuContainer">
          <nav className="navContainer">
            <section className="leftNav">
              <section className="logoContainer">
                <section className="logo" onClick={()=>props.top()}><NavLink to ="/" style={{ textDecoration: "none", color: "white" }}><Logo className='logoMain' /></NavLink></section>
                </section>
              <section className="pgLink about" onClick={()=>props.about()}>About&emsp;</section>
              <section className="pgLink serviceAreas" onClick={()=>props.locations()}>Service Areas</section>
              <section className="pgLink projects" onClick={()=>props.projects()}>Projects</section>
            </section>
          </nav>
        </section>
      </header>
    </div>
  );
}
export default Header;
