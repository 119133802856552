import React from 'react';
import '../styles/Footer.css'
import {ReactComponent as FooterLogo} from '../../src/Yardoli_Logo.svg'
import {Link} from 'react-router-dom'

function Footer(props) {
  return (
  <div>
      <section className='footer'>
          <section className='footerLogo'><Link to ="/" style={{ textDecoration: "none", color: "white" }} onClick={()=>props.top()}><FooterLogo className="logoIcon" /></Link>
          </section>
          <section className='madeBy'>&copy; 2022 The Yardoli Company LLC. All Rights Reserved.</section>
      </section>
  </div>
  );
} export default Footer
