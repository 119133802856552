import ReactDOM from "react-dom";
import React from 'react'
import "../styles/Modal.css";

function Modal({open, onClose}) {
    if(!open) return null;
  return ReactDOM.createPortal(
    <div>
      
      <section className='note'>
      <button type="button" className='btnCloseMsg' onClick={onClose}>&times;</button>
        <h1 className="thankyou">Thank You!</h1><br/>
        <p>Someone will contact you within 48 hours.</p>
      </section>
      <div className="overlay" onClick={onClose}></div>
    </div>,
    document.getElementById('Portal')
  )
}

export default Modal