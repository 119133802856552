import React from "react";
import Header from "./Header";
import "../styles/Home.css";
import { ReactComponent as Wave } from "../../src/wave.svg";
import Footer from "./Footer";
import { useEffect, useRef, useState } from "react";
import Modal from "./Modal";
import axios from "axios";
import mapboxgl from "mapbox-gl";

function Home() {
  const TopRef = useRef(null);
  const ServicesRef = useRef(null);
  const LocationsRef = useRef(null);
  const ProjectsRef = useRef(null);
  const AboutRef = useRef(null);
  const ContactRef = useRef(null);
  let [pressed, setPressed] = useState(false);
  let [startx, setStartX] = useState();
  let [x, setX] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [service, setService] = useState("");
  const [response, setResponse] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const slider = document.querySelector(".slider");
  const innerSlider = document.querySelector(".slider-inner");
  const slideImage = document.querySelector(".image");
  const tag = document.querySelector(".resultsTag");
  const afterImg = document.getElementById("afterImg");

  const updatedScreenSize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", updatedScreenSize);
    return () => window.removeEventListener("resize", updatedScreenSize);
  }, []);

  const gotoTop = () =>
    window.scrollTo({
      top: TopRef.current.offsetTop,
      behavior: "smooth",
    });
  const gotoServices = () => {
    window.scrollTo({
      top: ServicesRef.current.offsetTop - 120,
      behavior: "smooth",
    });
  };
  const gotoLocations = () =>
    window.scrollTo({
      top: LocationsRef.current.offsetTop - 430,
      behavior: "smooth",
    });
  const gotoProjects = () =>
    window.scrollTo({
      top: ProjectsRef.current.offsetTop - 120,
      behavior: "smooth",
    });
  const gotoAbout = () =>
    window.scrollTo({
      top: AboutRef.current.offsetTop - 120,
      behavior: "smooth",
    });
  const gotoContact = () =>
    window.scrollTo({
      top: ContactRef.current.offsetTop - 120,
      behavior: "smooth",
    });

  useEffect(() => {
    mapboxgl.accessToken =
      "pk.eyJ1Ijoib290aGViYWciLCJhIjoiY2t6NjNnYWc1MDVmNDJ1bDQxanJvNWxsbSJ9.m18ZUPuuuMaGQ5nOdaKg-g";
    new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/oothebag/ckz66jbv9004815p45ha7g9ow",
    });
  }, []);

  const checkboundry = () => {
    let outer = slider.getBoundingClientRect();

    if (parseInt(innerSlider.style.left) <= `${-parseInt(outer.width / 2)}`) {
      innerSlider.style.left = `${-parseInt(outer.width / 2)}px`;
      tag.style.left = 0;
    }
    if (parseInt(innerSlider.style.left) >= `${parseInt(outer.width / 2)}`) {
      innerSlider.style.left = `${parseInt(outer.width / 2)}px`;
      tag.style.left = `${parseInt(outer.width)}px`;
      afterImg.style.left = 0;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const checkboxes = document.getElementsByName("rdoService");
    const name = `${firstName} ${lastName}`;
    const text = `<div>Hello,<br /><br/> My name is <b>${
      name ? name : "no name"
    }</b> I would like to have a consultation about my yard's care.<br/> You can reach me at: <br /><b>phone :</b>${
      phone ? phone : "no phone number provided"
    } <br /><b>email:</b> ${
      email ? email : "no email address provided"
    }<br /> I'm interested in some <b>${
      service ? service : "unknown"
    }</b> as a service. I look forward to talking with you soon.</div>`;
    axios
      .post("/api/mail", { name, phone, service, email, text })
      .then((res) => setResponse(res.data))
      .catch((err) => console.log(err));
    setFirstName("");
    setLastName("");
    setPhone("");
    setService("");
    setEmail("");
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false;
    }
  };

  useEffect(() => {
    if (!response) return null;
    setIsOpen(true);
  }, [response]);

  useEffect(() => {
    if (slider) {
      let outer = slider.getBoundingClientRect();
      innerSlider.style.left = `${x - startx}px`;
      slideImage.style.left = `${parseInt(outer.width / 2) - (x - startx)}px`;
      tag.style.left = `${x - startx + parseInt(outer.width / 2)}px`;
    }
  }, [width]);

  return (
    <div width={width} height={height} className='mainContainer'>
      <div className="nav">
        <Header
          top={() => gotoTop()}
          services={() => gotoServices()}
          about={() => gotoAbout()}
          locations={() => gotoLocations()}
          projects={() => gotoProjects()}
        />
      </div>
      <div className="mainHomeContainer" ref={TopRef}>
        <section className="heroTitle">
          We're basically professional yardologists.
          <section className="heroSubtitle">
            Improve your curb appeal with proven remedies from yard care professionals.
          </section>
          <section className='callToAction'>
          <a href="mailto:k_slocum@outlook.com?subject=I'd Like a Free Consultation&body=Hello, 
        please reach out to me at your earliest convenience with rates and how I can get my yard looking its best as soon as possible!">
            <button className='portfolioButton'>
              Free Consultation
            </button>
            </a>
          </section>
        </section>
      </div>
      <section className="sectionBenefits">
        <Wave className="transitionWave main" />
        <section className="benefitsContainer">
          <article className="benefits">
            <section className="benefitTitle">
              Your Property Deserves High Quality Curb Appeal
            </section>
            <br />
            <br />
            <article className="benefitSubtitle">
              <h3 className="featureLabel">Improve Your Property's Value</h3>
            </article>
            <br />
            <article className="benefitText">
              <p>
                First impressions are lasting ones. Your yard can speak volumes
                without ever saying a word.{" "}
              </p>
            </article>
            <br />
            <article className="benefitSubtitle">
              <h3 className="featureLabel">Eliminate the HOA Headache</h3>
            </article>
            <br />
            <article className="benefitText">
              <p>
                Each property contributes aesthetics to the community.Why
                shouldn't yours look the best?
              </p>
            </article>
            <br />
            <article className="benefitSubtitle">
              <h3 className="featureLabel">Upkeep Your Lawn Effortlessly</h3>
            </article>
            <br />
            <article className="benefitText">
              <p>
                With our scheduled maintenance plans our Yardolis&#8482;
                (yar-dole-lees) will come out regularly to maintain your
                property and curate plant life cycles during upkeep.
              </p>
            </article>
            <br />
          </article>
        </section>
        <section className="formContainer" ref={ContactRef}>
          <section className="contactForm">
            <form className="formFields">
              <section className="formTitle">
                Request a Free Consultation
              </section>
              <input
                type="text"
                className="txtContact"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First Name"
              />
              <input
                type="text"
                className="txtContact"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name"
              />
              <input
                type="text"
                className="txtContact"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Phone Number"
              />
              <input
                type="text"
                className="txtContact"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
              <section title="serviceType" className="txtContact radio">
                <input
                  className="rdoLabel"
                  type="radio"
                  name="rdoService"
                  id="yardMaintenance"
                  onChange={(e) => setService(e.target.value)}
                  value="Maintenance"
                />
                <label htmlFor="rdoService">Maintenance</label>
                <input
                  className="rdoLabel"
                  type="radio"
                  name="rdoService"
                  id="pruning"
                  onChange={(e) => setService(e.target.value)}
                  value="Pruning"
                />
                <label htmlFor="pruning">Pruning</label>
                <input
                  className="rdoLabel"
                  type="radio"
                  name="rdoService"
                  id="wasteRemoval"
                  onChange={(e) => setService(e.target.value)}
                  value="Waste Removal"
                />
                <label htmlFor="wasteRemoval">Waste Removal</label>
                <input
                  className="rdoLabel"
                  type="radio"
                  name="rdoService"
                  id="other"
                  onChange={(e) => setService(e.target.value)}
                  value="Other"
                />
                <label htmlFor="other">Other</label>
              </section>
              <button
                type="button"
                className="txtContact btnContact"
                onClick={(e) => handleSubmit(e)}
              >
                SCHEDULE NOW
              </button>
            </form>
          </section>
        </section>
      </section>
      <section className="transitionSection">
        <Wave className="transitionWave top" />
        <article className="transitionText">We treat the aesthetics of your entire yard, not just your lawn.</article>
      </section>
      <section className="sectionFeatures">
        <Wave className="transitionWave bottom" />
        <section className="midContainer">
          <section className="slider-Container">
            <div
              className="slider"
              onMouseDown={(e) => {
                setPressed(true);
                if (innerSlider) {
                  let tab = e.nativeEvent.offsetX - innerSlider.offsetLeft;
                  setStartX(tab);
                  slider.style.cursor = "grabbing";
                }
              }}
              onTouchStart={(e) => {
                setPressed(true);
                if (innerSlider) {
                  let tab = e.changedTouches[0].pageX - innerSlider.offsetLeft;
                  setStartX(tab);
                  slider.style.cursor = "grabbing";
                }
              }}
              onMouseEnter={(e) => {
                const slider = e.target;
                slider.style.cursor = "grab";
              }}
    
              onMouseMove={(e) => {
                if (!pressed) return;
                e.preventDefault();
                let outer = slider.getBoundingClientRect();
                let x = e.nativeEvent.offsetX;
                innerSlider.style.left = `${x - startx}px`;
                slideImage.style.left = `${
                  parseInt(outer.width / 2) - (x - startx)
                }px`;
                tag.style.left = `${x - startx + parseInt(outer.width / 2)}px`;
                setX(x)
                checkboundry();
              }}
              onTouchMove={(e) => {
                if (!pressed) return;
                let outer = slider.getBoundingClientRect();
                let x = e.changedTouches[0].pageX
                innerSlider.style.left = `${x - startx}px`;
                slideImage.style.left = `${
                  parseInt(outer.width / 2) - (x - startx)
                }px`;
                tag.style.left = `${x - startx + parseInt(outer.width / 2)}px`;
                setX(x)
                checkboundry();
              }}
              onMouseUp={(e) => {
                const slider = e.target;
                slider.style.cursor = "grab";
                setPressed(false);
              }}
              onMouseLeave={() => {
                setPressed(false);
              }}
              onTouchEnd={() => {
                setPressed(false);
              }}
            >
              <div className="slider-inner">
                <div className="slide-img">
                  <img
                    id="afterImg"
                    className="image"
                    src="https://contractor-site-demo.s3.us-west-1.amazonaws.com/afterPicture.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="resultsTag">
                <h5>AFTER &emsp; BEFORE</h5>
              </div>
            </div>
          </section>
          <article className="featureContainer">
            <section className="feature">
              <section className="imageFeature one"></section>
              <br />
              <section className="textFeature">
                <h3 className="featureLabel">CARE FREE MAINTENANCE</h3>
                <br />
                <p>
                  We cut, trim and maintain your property so you don't have to.
                </p>
              </section>
            </section>
            <section className="feature">
              <section className="imageFeature two"></section>
              <br />
              <section className="textFeature">
                <h3 className="featureLabel">RAPID TURNAROUND TIME</h3>
                <br />
                <p>
                  We'll get back to you within 48 hours or your first cut is
                  free.
                </p>
              </section>
            </section>
            <section className="feature">
              <section className="imageFeature three"></section>
              <br />
              <section className="textFeature">
                <h3 className="featureLabel">PROFESSIONAL LANDSCAPING</h3>
                <br />
                <p>
                  Our Yardolis are trained professionals with an eye for area
                  appropriate cosmetics.
                </p>
              </section>
            </section>
          </article>
        </section>
      </section>
      <section className="sectionAbout">
        <section className="aboutUs">
          <section className="aboutImage"></section>
          <article className="aboutText">
            <article className="aboutTitle" ref={AboutRef}>
              <h3 className="featureLabel">ABOUT</h3>
            </article>
            <br />
            <article className="aboutBody">
              <article className="formTitle dark">
                Our business is to make your property shine.
              </article>
              <br />
              <article>
                <p>
                  We make a five point inspection of soil quality, spot
                  stresses, landscape maximization, water conservation and
                  foliage cover for your yard. If we find opportunities to
                  improve the look and gardening efficacy of your land we'll let
                  you know.
                  <br /> Our mission is to provide you with a Yardoli that you
                  will come to know and trust with the care and maintenance of
                  your property for years to come.
                </p>
              </article>
            </article>
          </article>
        </section>
      </section>

      <section className="transitionSection location">
        <Wave className="transitionWave top location" />
        <article className="locationText">
          <h3>SERVICE AREAS</h3>
          <br />
          <article className="locationSubText">
            <span>
              <h4>
                LONG
                BEACH&ensp;|&ensp;TORRANCE&ensp;|&ensp;CARSON&ensp;|&ensp;SIGNAL
                HILL
              </h4>
            </span>
          </article>
        </article>
      </section>
      <section className="sectionLocations" id="locations" ref={LocationsRef}>
        <Wave className="transitionWave bottom location" />
        <section className="mapContainer">
          <section id="map" className="map"></section>
          <section className="mapLocations"></section>
        </section>
        <article className="locationLowerBar">
            <span className='lowerBarText'>
              <h4>
                PINCH MAP TO ZOOM IN/OUT
              </h4>
            </span>
          </article>
      </section>

      <article className="projectsTitle" ref={ProjectsRef}>
        <h3>PROJECTS</h3>
      </article>
      <section className="sectionProjects" id="projects">
        <article className="featureContainer">
          <section className="feature">
            <section className="imageFeature city1"></section>
            <br />
            <section className="textFeature">
              <h3 className="featureLabel">LONG BEACH, CA</h3>
              <br />
              <button type="button" className="portfolioButton">
                Click for Details
              </button>
            </section>
          </section>
          <section className="feature">
            <section className="imageFeature city2"></section>
            <br />
            <section className="textFeature">
              <h3 className="featureLabel">TORRANCE, CA</h3>
              <br />
              <button type="button" className="portfolioButton">
                Click for Details
              </button>
            </section>
          </section>
          <section className="feature">
            <section className="imageFeature city3"></section>
            <br />
            <section className="textFeature">
              <h3 className="featureLabel">CARSON, CA</h3>
              <br />
              <button type="button" className="portfolioButton">
                Click for Details
              </button>
            </section>
          </section>
          <section className="feature">
            <section className="imageFeature city4"></section>
            <br />
            <section className="textFeature">
              <h3 className="featureLabel">SIGNAL HILL, CA</h3>
              <br />
              <button type="button" className="portfolioButton">
                Click for Details
              </button>
            </section>
          </section>
        </article>
        <section className="fullList">
          <button
            type="button"
            onClick={() => gotoContact()}
            className="btnConsultation"
          >
            Request a FREE Consultation
          </button>
        </section>
      </section>
      <Modal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          setResponse(false);
        }}
      />
      <Footer top={() => gotoTop()} />
    </div>
  );
}
export default Home;
